import React, { useState } from 'react'
import InputBox from '../InputBox';
import Heading from '../ParagraphItems/Heading';
import OfficeInformation from '../ParagraphItems/OfficeInformation';
import RelationToApplicant from '../ParagraphItems/RelationToApplicant';
import SarjinPresent from '../ParagraphItems/SarjinPresent';
import Sender from '../ParagraphItems/Sender';
import SelectBox from '../SelectBox';
import { bold, businessCloseItems, businessOpenType, businessTransferSpecificItems } from '../Variables';
import { isValidDate } from '../Variables/TextVariables';
export default function Business(prop) {
    const props = prop.prop;
    const receiver = props.person.receiver;
    const details = props.details;
    const [applicantRelation, setApplicantRelation] = useState({});
    const [sarjin, setSarjin] = useState({});

    const businessWorkTypes = ["व्यवसाय बन्द भएको", 'व्यवसाय सञ्चालन नभएको', 'व्यवसाय नभएको', 'नयाँ व्यवसाय दर्ता', 'व्यवसाय नविकरण']
    const businessWork = details.businessWork ? details.businessWork : businessWorkTypes[0];
    const componentRef = props.componentRef;
    const handleChange = (value) => {
        props.detailsUpdater("businessWork", value);
    }

    const businessIndex = businessWorkTypes.indexOf(businessWork);
    const itemsToMap = businessIndex < 3 ? businessCloseItems : businessOpenType;
    const inputs = itemsToMap.map((items, index) => {

        if (index === 0 && businessIndex < 3) items = businessWork + " " + businessCloseItems[index]

        return <div className="col" key={index}>

            {businessIndex < 3 && (businessIndex === 0 || index !== 1) && <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, isValidDate(value))
            }} />}

            {businessIndex > 2 && <InputBox field={items} value={details[items]} handleChanges={(field, value) => {
                props.detailsUpdater(field, value)
            }} />}


        </div>
    })

    return (
        <div>
            <div className="row justify-content-center" key={1}>
                <div className="col-6">
                    <hr />
                    <SelectBox value={details["businessWork"]} options={businessWorkTypes} handleChanges={handleChange} />
                    <hr />
                </div>
            </div>
            <div className="row">
                <RelationToApplicant details={details} detailsUpdater={props.detailsUpdater} setRelation={setApplicantRelation} />
                {inputs}
                <SarjinPresent details={details} detailsUpdater={props.detailsUpdater} setSarjin={setSarjin} date={props.date} />

            </div>

            <div className='businessClose' ref={componentRef} contentEditable="true">
                <OfficeInformation />
                <Heading receiver={receiver} topic={businessWork + " सिफारिस"} date={props.date} />
                <br />


                {businessIndex < 3 && <div className='content' align='justify'>
                    {props.starter} {businessWork} सिफारिस गरिदिन दिनु भएको निवेदन अनुसार {applicantRelation.personMention}को नाममा रहेको <b>{`"${details[businessTransferSpecificItems[0]]}"`} {typeof details[businessTransferSpecificItems[1]] !== 'undefined' && details[businessTransferSpecificItems[1]] !== '' ? 'संकेत नं ' + details[businessTransferSpecificItems[1]] : ''}</b> व्यवसाय {bold(details[businessTransferSpecificItems[2]])} कारणले मिति {bold(details[businessWork + " " + businessCloseItems[0]])} देखि {businessWork === businessWorkTypes[0] && bold("मिति " + details[businessCloseItems[1]] + " सम्म")} {businessWork} व्यहोरा {bold(sarjin.sarjinPresent && sarjin.sarjinDetails + "का आधारमा")} स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(८) बमोजिम सिफारिस गरिन्छ ।
                </div>}
                {
                    businessIndex >= 3 && <div className='content' align='justify'>
                        {props.starter} यस वडाको {details[businessOpenType[1]]}मा {businessIndex === 4 && "रहेको "} {businessWork}का लागि सिफारिस गरिदिन दिनु भएको निवेदन अनुसार {applicantRelation.personMention}को नाममा {businessIndex === 4 && "रहेको "}<b>{`${details[businessTransferSpecificItems[0]]} `}({details[businessOpenType[0]] + "  प्रकृतिको"})</b> {sarjin.sarjinPresent ? "व्यवसाय " + sarjin.sarjinDetails + `ले ${businessWork} गर्न उपयुक्त हुने देखिएकाले` : businessWork} नियमानुसार {sarjin.sarjinPresent && businessWork + " "}गरिदिनुहुन स्थानीय सरकार सञ्चालन ऐन, २०७४ को दफा १२(२)ङ(८) बमोजिम सिफारिस गरिन्छ ।
                    </div>
                }


                <br />
                <div className='signature' align='right'>
                    <Sender person={props.person} />

                </div>
            </div>
        </div >
    )
}
